<template>
  <div>
    <!-- tabs栏 -->
    <div class="tabs">
      <div
        class="tabs_item"
        :class="{ tabsList1: active == 0 }"
        @click="changeTabs(0)"
      >
        <div v-if="noticeNum" class="badeg">{{ noticeNum }}</div>
        <div>通知</div>
        <div v-if="active == 0" class="border"></div>
      </div>
      <div
        class="tabs_item"
        :class="{ tabsList1: active == 1 }"
        @click="changeTabs(1)"
      >
        <div v-if="msgNum" class="badeg">{{ msgNum }}</div>
        <div>公告</div>
        <div v-if="active == 1" class="border"></div>
      </div>
      <!-- <div
        v-if="!read"
        class="read"
        :class="{ readMove: readMove }"
        @mouseenter="readMove = true"
        @mouseleave="readMove = false"
      >
        <div @click="goRead">全部标记为已读</div>
      </div>
      <div class="read noRead" v-else>
        <div>已标记为已读</div>
      </div>-->
    </div>
    <div class="list">
      <div class="item1 flex" v-for="(item, index) in msgList" :key="index">
        <div class="top flex">
          <div class="tit flex1" @click="open(item.id, item.is_real)">
            <span v-if="item.is_real == 0"></span>
            <!-- <div class="name">【系统公告】</div> -->
            <div class="title">{{ item.title }}</div>
          </div>
          <!-- <div class="iconImg">
            <img src="../../assets/img/personal/icon_5_6.png" alt="" />
          </div>-->
        </div>
        <div class="num">{{ (item.create_time * 1000) | dateFormat }}</div>
      </div>
    </div>
    <el-dialog :visible="dialogVisible" width="30%" :before-close="handleClose">
      <div slot="title" class="di-top">
        <div class="title1">{{ detailItem.title }}</div>
        <div class="di_time">
          {{ (detailItem.create_time * 1000) | dateFormat }}
        </div>
      </div>
      <span v-html="detailItem.content"></span>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>-->
    </el-dialog>
    <!-- 分页 -->
    <div class="page" v-if="msgList.length > 0">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { msgList, msgDetail, msgReal } from "../../assets/js/personal";
// let dialogShow = false;
// let detail = {};
import Bus from "../../bus.js";
export default {
  data() {
    return {
      active: 0,
      current: -1,
      msgNum: 5,
      msgNum2: 9,
      currentPage: 1,
      size: 5,
      total: "",
      dialogVisible: false,
      tabs: ["通知", "公告"],
      readMove: false,
      read: false,
      msgList: [],
      // 通知数量
      noticeNum: "",
      // 公告数量
      msgNum: "",
      detailItem: {},
    };
  },
  created() {},
  destroyed() {
    window.sessionStorage.setItem("headerMSG", false);
  },
  mounted() {
    let flag = window.sessionStorage.getItem("headerMSG");
    // console.log(flag);
    if (flag == "true") {
      this.dialogVisible = true;
      this.detailItem = JSON.parse(window.sessionStorage.getItem("detail"));
      window.sessionStorage.getItem("isReal");
      let isReal = window.sessionStorage.getItem("isReal");
      // 消息已读
      if (isReal == 0) {
        msgReal({
          siteId: window.localStorage.getItem("siteId"),
          user_id: JSON.parse(window.localStorage.getItem("userInfo"))
            ? JSON.parse(window.localStorage.getItem("userInfo")).id
            : "",
          a_id: this.detailItem.id,
        }).then((res1) => {
          // console.log(res1);
          if (res1.code == 200) {
            this.getMsgList();
          }
        });
      }
    }
    this.type = this.active + 1;
    this.getMsgList();
  },
  methods: {
    // 获取消息列表
    getMsgList() {
      msgList({
        siteId: window.localStorage.getItem("siteId"),
        type: this.type,
        user_id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
        offset: this.currentPage,
        limit: this.size,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.msgList = res.data.list;
          this.total = res.data.count;
          this.msgNum = res.data.notice;
          this.noticeNum = res.data.notify;
        }
      });
    },
    changeTabs(i) {
      this.currentPage = 1;
      this.active = i;
      this.type = i + 1;
      this.getMsgList();
    },
    open(id, is_real) {
      // 获取消息详情
      msgDetail({
        a_id: id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.detailItem = res.data.info;
          this.$forceUpdate();
          // this.$nextTick(function () {
          this.dialogVisible = true;
          // });
          // 消息已读
          if (is_real == 0) {
            msgReal({
              siteId: window.localStorage.getItem("siteId"),
              user_id: JSON.parse(window.localStorage.getItem("userInfo"))
                ? JSON.parse(window.localStorage.getItem("userInfo")).id
                : "",
              a_id: id,
            }).then((res1) => {
              // console.log(res1);
              if (res1.code == 200) {
                this.getMsgList();
                Bus.$emit("read", true);
              }
            });
          }
        }
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
      window.sessionStorage.setItem("headerMSG", false);
    },
    goRead(i) {
      this.read = true;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getMsgList();
    },
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.active {
  color: #0090f0 !important;
  // font-weight: bold;
}
/deep/ .el-dialog {
  overflow: hidden;
}
.page {
  // width: 930px;
  margin: 49px auto;
  text-align: center;
}
.tabs {
  position: relative;
  width: 100%;
  // height: 50px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  // align-items: center;
  .read {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 16px;
    color: #343434;
    line-height: 60px;
    cursor: pointer;
    display: flex;
    // align-items: center;
  }
  .noRead {
    color: #666;
    cursor: default;
  }
  .tabs_item {
    position: relative;
    cursor: pointer;
    // width: 80px;
    font-size: 18px;
    color: #333333;
    height: 64px;
    line-height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    .border {
      border-bottom: 4px solid #0090f0;
    }
    .badeg {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 10px 8px;
      line-height: 1px;
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 12px;
      background-color: #ff0001;
      color: #fff;
    }
  }
}
.el-badge {
  display: flex;
}
.readMove {
  color: #029aff !important;
}
.item1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  padding: 20px 20px;
  border-bottom: 1px solid #f2f2f2;
  .iconImg {
    cursor: pointer;
  }
  .top {
    display: flex;
    justify-content: space-between;

    img {
      width: 15px;
      height: 16px;
    }
    .tit {
      cursor: pointer;

      .name {
        font-size: 16px;
        color: #333333;
      }
      span {
        width: 8px;
        height: 8px;
        background: #f13232;
        border-radius: 50%;
        margin-right: 8px;
      }
      .title {
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .num {
    margin-left: 5px;
    font-size: 12px;
    color: #999999;
  }
  &:hover {
    background-color: #f2f2f2;
    .title {
      color: #0090f0 !important;
    }
  }
}
.di-top {
  text-align: center;
  .title1 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .di_time {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
</style>